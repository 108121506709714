import React from 'react'
import App from 'App'
import { AppShellDataProps, AppShellDataProvider } from '@trr/app-shell-data'
import { createTheme, ThemeProvider } from '@mui/material'
import { themeOptions, defaultLocale } from '@trr/mui-theme'
import { ApiProvider } from '@reduxjs/toolkit/dist/query/react'
import api from 'api'

const Index = ({ appShellData }: { appShellData: AppShellDataProps }) => {
  const theme = createTheme(themeOptions, defaultLocale)

  return (
    <ThemeProvider theme={theme}>
      <AppShellDataProvider value={appShellData}>
        <ApiProvider api={api}>
          <App />
        </ApiProvider>
      </AppShellDataProvider>
    </ThemeProvider>
  )
}

export default Index
