import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react/'
import { getIdToken } from '@trr/app-shell-data'
import { SentAuthorizationCodesResponse } from 'types/authorizationCode'
import { getConfig } from 'utils'

const { API_URL } = getConfig()

const trrFetchBaseQuery = () =>
  fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      headers.set('authorization', `Bearer ${getIdToken()}`)
      headers.set('Content-Type', 'application/json')
      headers.set('Accept-Language', 'sv')

      return headers
    },
  })

const api = createApi({
  baseQuery: trrFetchBaseQuery(),
  endpoints: (build) => ({
    getSentAuthorizationCodes: build.query<
      SentAuthorizationCodesResponse[],
      void
    >({
      query: () => `/kommunicera/sentauthorizationcodes`,
    }),
  }),
})

export default api
