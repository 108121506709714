import { Box, Card, Typography, Chip, Button, Divider } from '@mui/material'
import React from 'react'
import { ListChildComponentProps } from 'react-window'
import relativeTime from 'dayjs/plugin/relativeTime'
import {
  IContent,
  Status,
  SentAuthorizationCodesResponse,
  StatusTransformArray,
} from 'types'
import dayjs from 'dayjs'
import 'dayjs/locale/sv'
import { useEpiContent } from '@trr/app-shell-data'
import { Email, Phone, FiberManualRecord } from '@mui/icons-material'
import { themeOptions } from '@trr/mui-theme'

dayjs.locale('sv')
dayjs.extend(relativeTime)

interface ListChildComponentPropsExtended extends ListChildComponentProps {
  handleClickOpen: (status: Status) => void
  data: SentAuthorizationCodesResponse[]
}

const AuthorizationCodesListRow = (props: ListChildComponentPropsExtended) => {
  const {
    index,
    style,
    data: filteredAuthorizationCodes,
    handleClickOpen,
  } = props
  const {
    verifieringskoder: {
      showActionsButtonText,
      statusNew = 'Ny',
      statusSending = 'Skickar',
      statusSendFailed = 'Sändning misslyckades',
      statusWaitingForDelivery = 'Väntar på leverans',
      statusDelivered = 'Levererad',
      statusDeliveryFailed = 'Leverens misslyckades',
    },
  } = useEpiContent<IContent>()

  const statuses: StatusTransformArray = {
    new: {
      statusName: statusNew,
      chipVariant: 'status-warning',
      showActions: false,
    },
    sending: {
      statusName: statusSending,
      chipVariant: 'status-info',
      showActions: false,
    },
    sendFailed: {
      statusName: statusSendFailed,
      chipVariant: 'status-error',
      showActions: false,
    },
    waitingForDelivery: {
      statusName: statusWaitingForDelivery,
      chipVariant: 'status-info',
      showActions: false,
    },
    delivered: {
      statusName: statusDelivered,
      chipVariant: 'status-success',
      showActions: false,
    },
    deliveryFailed: {
      statusName: statusDeliveryFailed,
      chipVariant: 'status-error',
      showActions: false,
    },
  }

  return (
    <Box
      style={{ ...style }}
      key={index}
      component={'li'}
      data-testid="authorization-code-list-item"
    >
      <Card sx={{ height: '110px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'space-between',
            padding: '16px 0px 8px 0',
          }}
        >
          <Box sx={{ padding: '0 0 0 14px ' }}>
            {filteredAuthorizationCodes?.[index]?.type === 'sms' ? (
              <Box
                sx={{
                  background: themeOptions.palette?.surface?.purple,
                  display: 'inline-block',
                  width: '32px',
                  height: '32px',
                  borderRadius: '50%',
                  textAlign: 'center',
                }}
              >
                <Phone
                  color={'primary'}
                  sx={{ verticalAlign: 'middle', fontSize: '16px' }}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  background: themeOptions.palette?.surface?.purple,
                  display: 'inline-block',
                  width: '32px',
                  height: '32px',
                  borderRadius: '50%',
                  textAlign: 'center',
                }}
              >
                <Email
                  color={'primary'}
                  sx={{ verticalAlign: 'middle', fontSize: '16px' }}
                />
              </Box>
            )}
            <Typography
              variant="subtitle2"
              sx={{ marginLeft: '8px' }}
              display={'inline'}
            >
              {filteredAuthorizationCodes?.[index]?.contact}
            </Typography>
          </Box>
          <Box>
            <Divider />
          </Box>
          <Box sx={{ display: 'flex', padding: '0 0 0 24px' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
              }}
            >
              <Chip
                variant={
                  statuses[filteredAuthorizationCodes?.[index]?.status]
                    .chipVariant
                }
                label={
                  statuses[filteredAuthorizationCodes?.[index]?.status]
                    .statusName
                }
              />
              <FiberManualRecord
                sx={{ fontSize: '6px', marginRight: '12px' }}
                htmlColor={themeOptions.palette?.text?.secondary}
              />
              <Typography variant="caption">
                {dayjs().to(filteredAuthorizationCodes?.[index]?.sendTime)}
              </Typography>
            </Box>
            {['deliveryFailed', 'sendFailed'].includes(
              filteredAuthorizationCodes?.[index]?.status as string
            ) && (
              <Box sx={{ marginLeft: 'auto' }}>
                <Button
                  variant="text"
                  onClick={() =>
                    handleClickOpen(filteredAuthorizationCodes?.[index]?.status)
                  }
                  sx={{ padding: '0', margin: '0 24px 0 0' }}
                >
                  {showActionsButtonText}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Card>
    </Box>
  )
}

export default AuthorizationCodesListRow
