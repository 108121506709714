import React, { useState } from 'react'
import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import api from 'api'
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  colors,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { IContent, Status } from 'types'
import { useEpiContent } from '@trr/app-shell-data'
import HTMLMapper from '@trr/html-mapper'

import AuthorizationCodesListRow from '../AuthorizationCodesListRow'

const AuthorizationCodesList = ({ searchQuery }: { searchQuery: string }) => {
  const { isLoading, data: sentAuthorizationCodes } =
    api.useGetSentAuthorizationCodesQuery()

  const {
    actionDeliveryFailed: {
      name: actionDeliveryFailedName,
      mainBody: actionDeliveryFailedBody,
    },
    actionSendFailed: {
      name: actionSendFailedName,
      mainBody: actionSendFailedBody,
    },
  } = useEpiContent<IContent>()

  const [currentStatus, setCurrentStatus] = useState<Status>('new')
  const [open, setOpen] = useState(false)

  const handleClickOpen = (status: Status) => {
    setOpen(true)
    setCurrentStatus(status)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const filteredAuthorizationCodes = sentAuthorizationCodes?.filter((el) =>
    el.contact.includes(searchQuery)
  )

  if (isLoading)
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    )

  return (
    <>
      <AutoSizer>
        {({ height, width }: { width: number; height: number }) => {
          return (
            <FixedSizeList
              itemData={filteredAuthorizationCodes}
              height={height - 40 || 1000}
              width={width || 500}
              itemSize={140}
              itemCount={filteredAuthorizationCodes?.length ?? 0}
              innerElementType={'ul'}
            >
              {(props) => (
                <AuthorizationCodesListRow
                  {...props}
                  handleClickOpen={handleClickOpen}
                />
              )}
            </FixedSizeList>
          )
        }}
      </AutoSizer>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">
          <>
            {currentStatus === 'sendFailed'
              ? actionSendFailedName
              : actionDeliveryFailedName}
            <IconButton
              aria-label={'stäng'}
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[800],
              }}
            >
              <Close sx={{ color: colors.grey[700] }} />
            </IconButton>
          </>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" component={'div'}>
            <HTMLMapper
              body={
                currentStatus === 'sendFailed'
                  ? actionSendFailedBody
                  : actionDeliveryFailedBody
              }
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default AuthorizationCodesList
